<template>
  <div class="box">
    <div class="title">{{dataInfo.name}}</div>
    <div class="formBox">
      <el-form :model="dataForm" :rules="dataRules" ref="dataForm" label-width="120px" class="demo-ruleForm apply-form" label-position="right">
        <el-form-item label="人员类型">
          <el-select class="w100" v-model="dataForm.infoCategory" placeholder="请选择人员类型" filterable @change="infoCategoryChange">
            <el-option v-for="(item,index) in infoCategoryList" :key="index" :label="item.infoCategoryName" :value="item.infoCategory"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="dataForm.name" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-radio-group v-model="dataForm.gender">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="头像" prop="photo">
          <el-upload
              class="avatar-uploader"
              :action="uploadImg()"
              :headers="uploadHeaders()"
              :show-file-list="false"
              accept=".jpg,.png,jpeg"
              :on-success="handleSuccessPhoto">
            <img v-if="dataForm.photo" :src="loadUrl(dataForm.photo)" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="学历" prop="education">
          <el-select class="w100" v-model="dataForm.education" placeholder="请选择学历"  filterable clearable >
            <el-option v-for="(item, index) in educationArr" :key="index" :label="item.fullName" :value="item.fullName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证" prop="certificatesNumber">
          <el-input v-model="dataForm.certificatesNumber" placeholder="请输入身份证" maxlength="18"/>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobilePhone">
          <el-input v-model="dataForm.mobilePhone" placeholder="请输入联系电话" maxlength="11"/>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="dataForm.email" placeholder="请输入邮箱" />
        </el-form-item>
        <el-form-item label="联系地址" prop="area">
          <el-cascader :options="regionData" :props="{ checkStrictly: true }" v-model="addressData" :placeholder=" dataForm.province + dataForm.city + dataForm.area" clearable @change="handleChange" style="width: 40%"></el-cascader>
          <el-input v-model="dataForm.address" placeholder="请输入详细地址" clearable style="width: 58%; float: right"/>
        </el-form-item>
        <el-form-item label="工作单位" prop="company">
          <el-input v-model="dataForm.company" placeholder="请输入工作/训练单位" />
        </el-form-item>
        <el-form-item v-for="(item,index) in fileForms" :key="index" :label="item.label" :prop="item.vModel">
          <template v-if="item.tagIcon === 'radio'">
            <el-radio-group v-model="dataForm[item.vModel]" :placeholder="item.placeholder">
              <el-radio v-for="iRadio in item.options" :key="iRadio.value" :value="iRadio.value" :label="iRadio.label"></el-radio>
            </el-radio-group>
          </template>
          <template v-if="item.tagIcon === 'select'">
            <el-select class="w100" v-model="dataForm[item.vModel]" :placeholder="item.placeholder">
              <el-option v-for="iSelect in item.options" :key="iSelect.name" :name="iSelect.name" :value="iSelect.name" :label="iSelect.value"></el-option>
            </el-select>
          </template>
          <template v-if="item.tagIcon === 'input'">
            <el-input v-model="dataForm[item.vModel]" type="text" :label="item.label"
                      :maxlength="item.maxlength" :readonly="item.readonly" :placeholder="item.placeholder"
                      :rules="[ { required: item.required, message: item.placeholder },
                          { pattern: [item.regList].pattern, message: [item.regList].message, },
                        ]">
            </el-input>
          </template>
          <template v-if="item.tagIcon === 'textarea'">
            <el-input v-model="dataForm[item.vModel]" type="textarea" :label="item.label"
                      :maxlength="item.maxlength" :readonly="item.readonly" :placeholder="item.placeholder"
                      :rules="[
                          { required: item.required, message: item.placeholder },
                          { pattern: [item.regList].pattern, message: [item.regList].message, },
                        ]">
            </el-input>
          </template>
          <template v-if="item.tagIcon === 'upload'">
            <el-upload :class="{ noValid: !item.required }" :action="item['list-type'] === 'text' ? uploadFiles() : uploadImg()"
                       :headers="uploadHeaders()"
                       :on-success=" (res, file, fileList) => { return uploadSuccess(res, file, fileList, item.vModel); }"
                       :on-remove=" (file, fileList) => { return uploadRemove(file, fileList, item.vModel); }"
                       :multiple="item.multiple"
                       :file-list="fileList"
                       :placeholder="'请上传'+item.label"
                       style="width: 600px;"
            >
              <el-button size="small" type="primary">{{ item.buttonText }}</el-button>
              <div slot="tip" class="el-upload__tip" v-if="item.showTip">只能上传不超过 {{item.fileSize }}{{ item.sizeUnit }} 的{{item.accept}}文件</div>
            </el-upload>
          </template>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn-bar">
      <el-button type="primary" @click="submitForm" :loading="btnLoading">提交</el-button>
      <el-button @click="resetForm()">取消</el-button>
    </div>
  </div>
</template>

<script>
import {baseInfoInfoCategoryEdit, baseInfoInfoCategoryOps, baseInfoPersonnelFormPage} from "../../../api/template";
import {dict} from "../../../api/dict";
import { regionData } from "element-china-area-data";
export default {
  name: "registration.vue",
  data(){
    return{
      btnLoading:false,
      dataInfo:{},
      dataFieldJson:{},
      dataForm:{
        address: "",
        area: "",
        certificatesNumber: "",
        city: "",
        company: "",
        education: "",
        email: "",
        gender: "",
        id: "",
        infoCategory: "",
        infoCategoryName: "",
        mobilePhone: "",
        name: "",
        photo: "",
        province: "",
      },
      dataRules:{
        infoCategory: [
          { required: true, message: "请选择人员类型", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        gender: [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        photo: [
          { required: true, message: "请上传头像", trigger: "change" },
        ],
        education: [
          { required: true, message: "请选择学历", trigger: "change" },
        ],
        certificatesNumber: [
          { required: true, message: "请输入身份证", trigger: "blur" },
          { pattern:/^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/,message: '请输入正确的身份证', trigger: "blur" }
        ],
        mobilePhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { pattern:/^1[3456789]\d{9}$/,message: '请输入正确的联系电话', trigger: "blur" }
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { pattern:/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,message: '请输入正确的邮箱', trigger: "blur" }
        ],
        area: [
          { required: true, message: "请选择联系地址", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        company: [
          { required: true, message: "请输入工作单位", trigger: "blur" },
        ],
      },
      infoCategoryList:[],
      educationArr:[],
      regionData: regionData,
      addressData:[],
      fileForms:[],
      infoCategory:''
    }
  },
  created() {
    this.getBaseInfoPersonnelFormPage()
    this.getDictionaryList()
    this.infoCategory = this.$route.params.infoCategory
  },
  methods:{
    //获取人员模本
    getBaseInfoPersonnelFormPage(){
      let params = {
        currentPage:1,
        pageSize:20,
        organizeId:window.localStorage.getItem("domainTid"),
        infoCategory:''
      }
      baseInfoPersonnelFormPage(params).then(res=>{
        if(res.code == 200){
          if(res.data.list.length>0){
            this.infoCategoryList = res.data.list
            res.data.list.forEach(item=>{
              if(item.infoCategory == this.infoCategory){
                this.dataInfo = item
                this.dataForm.infoCategory = item.infoCategory
                this.dataForm.infoCategoryName = item.infoCategoryName
                this.fileForms = JSON.parse(item.code)
                this.returnRequired()
              }
            })
          }else {
            this.dataInfo = ''
            this.fileForms = []
            this.infoCategoryList = []
          }
        }
      })
    },
    //增加是否必填
    returnRequired(){
      this.fileForms.forEach(item=>{
        this.$set(this.dataForm, item.vModel, item.defaultValue || "");
        this.$set(this.dataRules, item.vModel, { required: item.required, message: item.placeholder, trigger: "change", });
      })
    },
    // 学历字典
    getDictionaryList() {
      dict("6a6d6fb541b742fbae7e8888528baa16").then((res) => {
        this.educationArr = res.data.list;
      });
    },
    //选择人员类型
    infoCategoryChange(e){
      this.infoCategoryList.forEach(item=>{
        if(e == item.infoCategory){
          this.dataForm.infoCategory = item.infoCategory
          this.dataForm.infoCategoryName = item.infoCategoryName
          this.dataInfo = item
          this.fileForms = JSON.parse(item.code)
          this.returnRequired()
        }
      })
    },

    //选择联系地址
    handleChange(val) {
      this.regionData.forEach((item) => {
        if (item.value == val[0]) {
          this.dataForm.province = item.label;
          item.children.forEach((dates) => {
            if (dates.value == val[1]) {
              this.dataForm.city = dates.label;
              dates.children.forEach((tags) => {
                if (tags.value == val[2]) {
                  this.dataForm.area = tags.label;
                }
              });
            }
          });
        }
      });
    },
    //上传头像
    handleSuccessPhoto(res, file) {
      this.dataForm.photo = res.data.url
    },
    submitForm(){
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let arr = [this.dataForm].concat(this.fileForms);
          let params = {
            fieldJson: JSON.stringify(arr),
            id: this.dataForm.id,
            infoCategory: this.dataForm.infoCategory,
            infoCategoryName: this.dataForm.infoCategoryName,
          };
          this.btnLoading = true
          baseInfoInfoCategoryEdit(params).then(res=>{
            if(res.code == 200){
              this.$message({
                message: res.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.btnLoading = false;
                  this.$router.go(-1)
                },
              });
            }else {
              this.$message({
                message: res.msg,
                type: "error",
                duration: 1500,
                onClose: () => {
                  this.btnLoading = false;
                },
              });
            }
          })
        }
      })
    },
    resetForm(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.box{
  width: 1020px;
  min-height: 560px;
  background: #fff;
  margin: 20px auto;
  padding: 20px 90px;
  .title{
    font-size: 28px;
    padding-bottom: 20px;
    color: #333;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #d2d2d2 ;
  }
  .formBox{
    padding: 0 180px;
  }
  .btn-bar{
    margin: 20px auto;
    text-align: center;
  }
  .w50{
    width: 50%;
  }
  .w100{
    width: 100%;
  }
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
